body,
html {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    background-color: #15202b;
    color: #e1e8ed;
    line-height: 1.2;
}

.hero {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
}

.profile {
    text-align: center;
    transition: all 0.5s ease;
}

.profile-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-bottom: 20px;
    transition: all 0.5s ease;
}

.profile-name {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 5px;
    color: #ffffff;
    padding: 0px;
}

.profile-handle {
    font-size: 1.2em;
    color: #8899a6;
}

.profile-handle a {
    text-decoration: none;
    color: #8899a6;
}

.profile-handle a:hover {
    color: #ffffff;
}

.profile-bio {
    max-width: 600px;
    margin: 20px auto;
    text-align: center;
    color: #d9d9d9;
    line-height: 1.6;
}

.profile-bio a, .post a {
    color: #4A99E9;
}

.profile-bio a:hover, .post a:hover {
    color: #FFFFFF;
}

.profile-bio a:visited, .post a:visited {
    color: #4A99E9;
}

.social-links {
    display: inline-flex;
    align-items: center;
    margin-left: 7px;
}

.social-links a {
    margin-right: 7px;
}

.twitter-blue {
    color: #4A99E9;
}

.twitter-gold {
    color: #FFD33D;
}

.youtube-red {
    color: #FF0000;
}

.pure-white {
    color: #ffffff;
}

.feed {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.post {
    background-color: #192734;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.link-post:hover {
    background-color: #38444d;
}

.post-dummy {
    background-color: #192734;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    height: 300px;
    opacity: 0;
}

.post-content {
    margin-bottom: 15px;
    color: #e1e8ed;
}

.post-image {
    width: 100%;
    height: 200px;
    background-color: #38444d;
    margin-bottom: 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8899a6;
    font-style: italic;
    overflow: hidden;
}

.post-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.post-meta {
    color: #8899a6;
    font-size: 0.9em;
}

.sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #15202b;
    padding: 10px 0;
    display: flex;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.sticky-header-content {
    display: flex;
    align-items: center;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

.sticky-header .profile-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sticky-header .profile-img {
    width: 40px;
    height: 40px;
    margin: auto 10px;
    display: flex;
    align-items: center;
}

.sticky-header .profile-name {
    font-size: 1.2em;
    margin-bottom: 0;
}

.sticky-header .profile-handle {
    font-size: 1em;
}

.inline-embed {
    border: 1px solid #38444d;
    border-radius: 12px;
    margin-top: 15px;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    aspect-ratio: 16 / 9;
}

.crosspost {
    border: 1px solid #38444d;
    border-radius: 12px;
    padding: 15px;
    margin-top: 15px;
}

.crosspost:hover {
    background-color: #38444d;
    cursor: pointer;
}

.crosspost-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.crosspost-icon {
    margin-right: 5px;
    font-size: 1.2em;
    color: #ffffff;
}

.crosspost-source {
    font-weight: bold;
    color: #ffffff;
}

.crosspost-content {
    color: #e1e8ed;
}

.down-chevron {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0) translateX(-50%);
    }
    40% {
        transform: translateY(-10px) translateX(-50%);
    }
    60% {
        transform: translateY(-5px) translateX(-50%);
    }
}