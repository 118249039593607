/* Fullscreen container */
.voronoi-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

/* Make the canvas take up the full screen */
canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

/* Floating toolbar at the bottom center */
.toolbar {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
    background: rgba(25, 39, 52, 0.9);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Toolbar buttons */
button {
    background: none;
    border: 2px solid #fff;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
}

button.active {
    background-color: #fff;
    color: #000;
}

/* Color picker styling */
input[type="color"] {
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    margin: auto;
}

.welcome-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(25, 39, 52, 0.9);
    color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 80%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .welcome-card h2 {
    margin-top: 0;
  }
  
  .preset-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
  }
  
  .preset-buttons button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .preset-buttons button:hover {
    background-color: #0056b3;
  }

  .toolbar button.clear-button {
    background-color: #6c757d;
    border: none;
  }
  
  .toolbar button.clear-button:hover {
    background-color: #5a6268;
  }
  
  .toolbar button.save-button {
    background-color: #007bff;
    border: none;
  }
  
  .toolbar button.save-button:hover {
    background-color: #0056b3;
  }

  .toolbar button.save-button:disabled {
    background-color: #5a6268;
    cursor: not-allowed;
  }

  button.primary-button {
    background-color: #007bff;
    border: none;
  }

  button.primary-button:hover {
    background-color: #0056b3;
  }

  button.secondary-button {
    background-color: #6c757d;
    border: none;
  }

  button.secondary-button:hover {
    background-color: #5a6268;
  }

  button.success-button {
    background-color: #28a745;
    border: none;
  }

  button.success-button:hover {
    background-color: #218838;
  }

  button.danger-button {
    background-color: #dc3545;
    border: none;
  }

  button.danger-button:hover {
    background-color: #c82333;
  }
  
  .alert-dialog-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  
  .alert-dialog-content {
    background-color: rgba(25, 39, 52, 0.9);
    color: #fff;
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 500px;
    max-height: 85vh;
    padding: 25px;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  
  .alert-dialog-title {
    margin: 0;
    font-weight: 500;
    color: white;
    font-size: 17px;
  }
  
  .alert-dialog-description {
    margin-top: 10px;
    margin-bottom: 20px;
    color: white;
    font-size: 15px;
    line-height: 1.5;
  }
  
  .alert-dialog-button {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    gap: 10px;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    height: 35px;
  }
  
  .alert-dialog-cancel {
    background-color: white;
    color: white;
  }
  
  .alert-dialog-action {
    background-color: #dc3545;
    color: white;
  }
  
  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }